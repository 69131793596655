@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

// reset values and vars
@import './baseStyles.scss';
@import './variables.scss';

// common/global styles
@import './app.scss';
@import './forms.scss';
@import './typography.scss';

// component imports

@import '../components/footer';
@import '../components/pressReleaseCard';
@import '../components/archiveImageCard';
@import '../components/sponsorCarousel';
@import '../components/imageCarousel';

@import '../components/pages/homePage';
@import '../components/pages/registerPage';
@import '../components/pages/pressReleasePage';
@import '../components/pages/venuePage';
@import '../components/pages/archivePage/';
@import '../components/pages/liveStreamLinkPage/';
@import '../components/pages/schedulePage/';

@import '@kainos-applied-innovation/convention-frontend-components/dist/';



