.con-sponsor-wrapper {
    align-items: center;
    background-color: $lilac-background;
    padding: 10px;
    border-radius: 10px;
}

.con-sponsor-carousel-row{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    padding-bottom: 10px;
}

.con-sponsor-image{
    &__container {
      display: flex;
      flex-basis: 15%; 
    }

    &__src {
      padding: 6%;
      align-self: center;
    }
}

.con-partner-text{
    align-items: center;
    justify-content: space-around;

    &__event{
      align-items: left;
      color: $text-color;
      display: flex;
      flex-basis: 84%;
      padding-left: 10px;
    }

    &__media{
      align-items: right;
      color: $text-color;
      display: flex;
      flex-basis: 11%;
      padding-left: 5px;
    }
}

.con-partner-text__event{
  text-align: center;
  display: inline-block;
}