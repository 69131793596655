.con-schedule-button__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.con-schedule__button {
  text-align: center;
  width: 18%;
  margin: 1px 15px;
  padding: 5px 15px;
  color: $white;
  border-radius: 14px;
  cursor: pointer;

  &--societal {
    background-color: $societal;
  }

  &--technical {
    background-color: $technical;
  }

  &--societal:focus,
  &--societal.active {
    transform: scale(1.1);
    box-shadow: 0 0 0 3px $societal;
  }

  &--technical:focus,
  &--technical.active {
    transform: scale(1.1);
    box-shadow: 0 0 0 3px $technical;
  }
}

.con-heading {
  &__m {
    color: $link;
  }
}

.con-heading-schedule {
  margin: 10px 0;
  color: $link;
  font-weight: 700;

  &__xs {
    color: $link;
    font-size: 14px;

    &--bold {
      font-weight: bold;
      margin: 0 0 5px 0;
      font-size: 14px;
      color: $link;
    }

    &--societal {
      font-weight: bold;
      margin: 0 0 5px 0;
      font-size: 14px;
      color: $societal;
    }

    &--technical {
      font-weight: bold;
      margin: 0 0 5px 0;
      font-size: 14px;
      color: $technical;
    }
  }
}

.con-heading-schedule-subtitle {
    &__m {
      font-size: 16px;
      
      &--trackone {
        margin: 0 10px;
        color: $societal;
        font-weight: bold;
      }
      
      &--tracktwo {
        margin: 0 10px;
        color: $technical;
        font-weight: bold;
      }
    }
}

.livestream-subtitle {
  display: none;
  color: #676767;
}
