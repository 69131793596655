.con-form-group {
  width: 100%;
  margin-bottom: 10px;
}

.con-form-group__label {
  display: block;
  font-size: 19px;
  color: $text-color;
  line-height: 1.4;
  margin-bottom: 5px;
  width: 100%;
}

.con-form__button {
  margin: 10px 0;
  padding: 5px 10px;
  color: $white;
  border-radius: 3px;
  background-color: $button-color;
  cursor: pointer;

  &:focus {
    outline: 2px solid $outline-color;
    outline-offset: 2px;
  }
}

.con-error {
  color: $error;
}