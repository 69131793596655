.con-archive-button {
    width: 200px;
    margin: 50px 0 0 50px;
    padding: 5px 15px;
    color: $white;
    border-radius: 14px;
    background-color: $button-color;
    cursor: pointer;
}

.con-archive-button-wrap {
    padding-top: 10px;
}

.con-archive-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin: 0 auto;
  }

@media (max-width: 768px) {
    .con-archive-button-wrap {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 0px;
    }

    .con-archive-button {
        display: block;
        margin: 30px 0 0 30px;
        text-align: center;
        width: 200px;
        height: 10%;
        padding: 6px 15px;
    }
}