.con-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.con-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  margin: 250px 0 0 0;
  flex: 1;
  border-radius: 8px 8px 0 0 ;
  box-shadow: 0px -10px 10px -10px $black;
}

.con-video-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  margin: 0 0 50px 0;
  flex: 1;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px -10px 10px -10px $black;
}

.con-flex__container {
  margin: 30px 0;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.con-content {
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;

  &__heading {
    margin: 30px 0;
  }
}

.con-app__container {
  background-color: $background;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  background-image: url('../assets/images/aicon_background.webp');
  background-attachment: fixed;
  background-size: cover;
}

.con-link {
  color: $link;
  cursor: pointer;
  text-decoration: underline;
  margin: 10px 0;

  &:hover {
    filter: brightness(80%);
  }

  &__readmore {
    color: $text-color;
    cursor: pointer;
    text-decoration: underline;
    margin: 10px 0;

    &:hover {
      filter: brightness(80%);
    }
  }
}

.con-link__nounderline {
  color: $link;
  cursor: pointer;
  margin: 10px 0;

  &:hover {
    filter: brightness(80%);
  }

  &__readmore {
    color: $text-color;
    cursor: pointer;
    text-decoration: underline;
    margin: 10px 0;

    &:hover {
      filter: brightness(80%);
    }
  }
}

.con-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.con-twitter-widget {
  padding: 20px;

  &__inner {
    max-width: 1060px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1000px) {
  .con-container {
    margin: 250px 60px 0 60px;
  }
}