.con-logo {
  text-align: center;
  width: 200px;
  margin-left: -10px;
  display: block;
}

.con-text__align {
  text-align: center;
  padding: 20px;
}

.con-text {
  display: inline-block;
  text-align: left;
}

.con-save-date-image {
  padding-bottom: 30px;
  min-width: 100%;
  height: auto;
}

.con-calling-speakers__button {
  width: 150px;
  margin-top: 30px;
  padding: 5px 15px;
  color: $white;
  border-radius: 14px;
  background-color: $button-color;
  cursor: pointer;
}

.con-video-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  background-color: $lilac-background;
  margin-bottom: 20px;
}

.con-calling-speakers-card{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;

  &__container {
    border-radius: 10px;
    margin-bottom: 20px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

.con-link-button {
  background-color: $button-color;
  color: $white;
  border: none;
  cursor: pointer;
  font: inherit;
  display: inline-block;
  border-radius: 24px;
  width: fit-content;
  text-align: center;
  padding: 5px;
  transition: background-color 0.3s ease;
}

.con-link-button:hover {
  background-color: $link;
  cursor: pointer;
}

.con-deadline {
  text-align: center;
}

.con-heading__m {
  margin-bottom: 20px;
}

.subheader {
  color: $link;
}

.con-home-image {
  width: 60%;
  height: auto;
  object-fit: cover;
  padding: 3%;

  &__container {
      display: flex;
      justify-content: center;
      position: relative;
      border-radius: 10px;
      background-color: $lilac-background;
      margin-bottom: 30px;
    }
}

.con-link {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}