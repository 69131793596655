.con-venue-image {
    width: 60%;
    height: auto;
    object-fit: cover;
    padding: 3%;

    &__container {
        display: flex;
        justify-content: center;
        position: relative;
        border-radius: 10px;
        background-color: $lilac-background;
        margin-bottom: 30px;
      }
  }  

.map {
    height: 100%;
    padding-bottom: 100px;
}
