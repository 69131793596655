.con-partner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

.con-center {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 50px; /* Replace with the desired height of the container */
  width: 100%; /* This will ensure the container takes the full width available */
}

.magazine-link {
  display: inline-block;
  justify-content: center;
  text-align: center;
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  color: #fff;
  background-color: $link;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
}
  