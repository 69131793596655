.con-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  margin-top: auto;
  background-color: #2c2e2d;
}

.con-footer__heading {
  color: white;
}

.con-social-media__icons {
  margin-left: 10px;
}

.con-social-media {
  color: white;
  text-align: right;
  font-size: 1rem;
}