.con-press-card{
    display: flex;
    flex-direction: row;
    background-color: $lilac-background;
    border-radius: 10px;
    padding: 10px;
    margin-top: 30px;
}

.con-press-image{
    
    &__container {
    display: flex;
    flex-basis: 30%; 
    max-height: 260px;
    }
    &__src {
        object-fit: cover;
        padding: 5%;
    }
}

.con-press-text{
    display: flex;
    flex-basis: 70%;
    flex-direction: column;
    padding: 2%;
}
  