$white: #fff;
$border: #aaa;
$link: #7c3289;
$background: #f1f2f3;
$text-color: #676767;
$black: #0b0b0c;
$outline-color: #5e9ed6;
$button-color: #2c2e2d;
$error: #b22;
$lilac-background: #f3ecf3;
$societal: #006DB6;
$technical: #01983E;